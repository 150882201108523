import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { Container, Row, Col } from 'react-bootstrap';
import '../components/post.css';

const Adventure = ({ data }) => {
  const { title, content, image } = data.contentfulAdventure;
  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <Row>
          <Col>
            <div className="post">
              <div className="post-header">
                <h1>{title}</h1>
                
              </div>
              <div className="post-thumbnail">
                <img alt={title} src={image.fluid.src} className="post-image" />
              </div>
              <div className="post-content">
                <div dangerouslySetInnerHTML={{ __html: content.childContentfulRichText.html }}></div>
              </div>
              <Link to="/posts">View more posts</Link>
              <Link to="/">Back to Home</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Adventure;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulAdventure(slug: { eq: $slug }) {
      title
      slug
      content {
        childContentfulRichText {
          html
        }
      }
      image {
        fluid {
          src
        }
      } 
    }
  }
`;